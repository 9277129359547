<template>
    <div>
        <div class="step-2-margin">
            <h1 class="header-white-text">{{ $t('faq.emby.step2.channelsList') }}</h1>
            <a class="link text" target="_blank" :href="liveTvLink">{{ liveTvLink }}</a>
        </div>
        <div class="step-2-margin">
            <h1 class="header-white-text">{{ $t('faq.emby.step2.movies') }}</h1>
            <a class="link text" target="_blank" :href="moviesLink">{{ moviesLink }}</a>
        </div>
        <div class="step-2-margin">
            <h1 class="header-white-text">{{ $t('faq.emby.step2.tvShows') }}</h1>
            <a class="link text" target="_blank" :href="tvShowsLink">{{ tvShowsLink }}</a>
        </div>
        <p class="text step-2-margin">{{ $t('faq.emby.step2.makeShure') }}</p>
        <h1 class="header-white-text">{{ $t('faq.emby.step2.tvGuide') }}</h1>
        <p class="text step-2-margin">{{ $t('faq.emby.step2.select') }}</p>
        <div class="step-2-margin">
            <p class="text">{{ $t('faq.emby.step2.regular') }}</p>
            <span class="text">{{ $t('faq.emby.step2.utc') }}</span><a class="link text"
                target="_blank" :href="tvNowBest">{{ tvNowBest }}</a>
        </div>
        <div>
            <p class="text">{{ $t('faq.emby.step2.liteVersion') }}</p>
            <span class="text">{{ $t('faq.emby.step2.utc') }}</span><a class="link text"
                target="_blank" :href="tvNowBestLiteLink">{{ tvNowBestLiteLink }}</a>
        </div>
        <div class="screenshot">
            <img class="step-image" src="../assets/screen-2.png" alt="">
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters(['brandConfig']),
        liveTvLink() {
            return `${this.brandConfig.tvLink}/api/list/USERNAME/PASSWORD/m3u8/livetv`
        },
        moviesLink() {
            return `${this.brandConfig.tvLink}/api/list/USERNAME/PASSWORD/m3u8/movies`
        },
        tvShowsLink() {
            return `${this.brandConfig.tvLink}/api/list/USERNAME/PASSWORD/m3u8/tvshows`
        },
        tvNowBest() {
            return `${this.brandConfig.tvLink}/api/epg/USERNAME/PASSWORD/full.xml.gz`
        },
        tvNowBestLiteLink() {
            return `${this.brandConfig.tvLink}/api/epg/USERNAME/PASSWORD/lite.xml.gz`
        }
    }
}
</script>
<style scoped>
@import "../../../components/styles/guides-default-styles.css";
.header-white-text {
    font-size: 15px;
    font-weight: 700;
    line-height: 22.5px;
    margin-bottom: 8px;
}
.step-2-margin {
    margin-bottom: 32px;
}
</style>
